@import "../../../styles/flex";
@import "../../../styles/colors";

.stepperSurvey {
	&Container {
		@include flex(column, flex-start, center);

		width: 100%;
		height: 100%;
	}

	&Title {
		font-family: Muli,sans-serif;
		font-size: 16px;
		font-weight: 700;
		color: $tertiary-color;
		padding-top: 10px;
	}

	&Section {
		font-family: Muli,sans-serif;
		font-size: 18px;
		font-weight: bold;
		color: $tertiary-color;
		padding-top: 15px;
	}

	&FormArea {
		padding: 20px;
		max-width: 600px;
	}
}
.star {
	cursor: pointer;
	color: $gray;
}

.criticalText {
	color: $primary-color;
	font-size: 14px;
	padding-top: 10px;
}
.npsArea {
	@include flex(row, space-between, center);

	.nps {
		cursor: pointer;
		background-color: transparent;
		padding: 10px;
		font-size: 14px;
		color: $gray;
		outline: 0;
		border: 1px solid transparent;
		border-radius: 5px;
	}

	.nps:hover {
		border-color: $primary-color;
	}
	.npsSelected {
		background-color: $primary-color;
		color: #fff;
	}
}
