@import "../../../styles/flex";
@import "../../../styles/colors";

.stepperName{
	&Container {
		@include flex(column, flex-start, center);

		width: 100%;
		height: 100%;
	}

	&Title {
		font-family: Muli,sans-serif;
		font-size: 20px;
		font-weight: 700;
		color: $tertiary-color;
	}

	&Erro {
		font-size: 14px;
		font-weight: 700;
		color: #ff3c2d;
	}

	&FormArea {
		padding: 20px;
	}
}
