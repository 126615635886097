@import "../../../styles/colors";
@import "../../../styles/flex";
@import "../../../styles/dimentions";

.toolbar {
	@include flex(row, start, center);

	z-index: 999;

	background-color: $primary-color;
	height: $navbar-height;

	width: 100%;
	box-shadow: 0 4px 8px 0 rgba(163, 55, 55, 0.2);
	position: fixed;
	top: 0;

	nav {
		@include flex(row, start, center);

		width: 100%;

		img {
			height: 40px;
			margin-right: 25px;
		}

		.navbar-content {
			@include flex(row, start, center);

			width: 100%;
			justify-content: center;

			h1 {
				color: $secondary-color;
			}

			h2 {
				margin-right: 2.5rem;
				color: white;
			}
		}
	}

	.menu-button {
		margin-left: 0.75rem;
	}
}

.navbarPlaceholder {
	height: $navbar-height;
	width: 100%;
	visibility: hidden;
}
