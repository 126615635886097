@import "../../styles/colors";
@import "../../styles/dimentions";

.filledButton {
	position: relative;

	display: block;
	margin: 0px;
	padding: 0;

	overflow: hidden;

	cursor: pointer;

	border-width: 0;
	outline: none;
	border-radius: 8px;
	box-shadow: 0 1px 4px $shadow-color;

	background-color: $primary-color;
	* {
		color: $white;
		font-weight: 500;
		font-size: 18px;

		@media (max-width: $mobile-width) {
			font-size: 14px;
		}
	}

	transition: background-color .3s;

	&[disabled] {
		cursor: not-allowed;
		background-color: $light-gray;
		* {
			color: $gray;
		}

		&:hover, &:focus {
			background-color: $light-gray;
		}
	}

	& > * {
		position: relative;
	}

	& span {
		display: block;
		padding: 12px 30px;
	}

	&:before {
		content: "";

		position: absolute;
		top: 50%;
		left: 50%;

		display: block;
		width: 0;
		padding-top: 0;

		border-radius: 100%;

		background-color: rgba(236, 240, 241, .3);

		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}

	&:active:before {
		width: 120%;
		padding-top: 120%;

		transition: width .2s ease-out, padding-top .2s ease-out;
	}
}

