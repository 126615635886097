@import "../../styles/colors";
@import "../../styles/flex";
@import "../../styles/dimentions";

.hotelCard {

	&Container {
		@include flex(row, flex-start, flex-start);

		width: 600px;
		box-sizing: border-box;
		margin-top: 60px;
	}

	&Image {
		@include flex(row, center, center);

		width: 40%;
		height: 100%;

		&Container {
			@include flex(row, center, center);

			height: 100%;
			text-align: center;

			img {
				max-height: 150px;
				width: 70%;
				object-fit: cover;
			}
		}
	}

	&Details {
		width: 60%;
		padding: 20px;
	}

	&Title {
		font-family: Muli,sans-serif;
		font-size: 20px;
		font-weight: 700;
		color: $tertiary-color;
		margin: 0;
	}

	&Description {
		font-size: 13px;
		font-family: "Muli",sans-serif;
		font-weight: bold;
		color: #373737;
	}
}
