@import "../../styles/flex";
@import "../../styles/dimentions";

.stepperPageContainer {
	@include flex(column, flex-start, center);

	width: 100%;
	height: 100%;

	.textFieldContainer {
		width: 100%;
	}
}

.stepperButton {
	@include flex(row, center, center);

	box-sizing: border-box;
	flex-wrap: wrap;


	.filledButton {
		margin: 10px;
		box-sizing: border-box;
	}

	@media (max-width: $mobile-width) {
		@include flex(row, center, center);

		width: 100%;

		.filledButton {
			width: 100%;
		}
	}

	margin: 0 auto 20px auto;
}
