@import "../../styles/colors";

.mainLayoutContainer {
	background-color: $background-color;

	width: 100%;
	height: 100vh;
	padding: 0;

	margin: 0;

	.mainLayoutChildrenContainer {
		min-height: 80%;
	}
}
