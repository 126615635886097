// MARK: General
$white: #FFFFFF;
$light-gray: #D0D0D0;
$gray: #818181;
$black: #000000;

// MARK: Logo
$primary-color: #232323;
$secondary-color: #FFC200;
$tertiary-color: #d8a400;

// MARK: Components
// Text
$dark-text-color: $black;
$text-color: $gray;

// Background
$background-color: #F8F8F8;

// Error
$error-color: #c0392b;

// Shadow
$shadow-color: rgba(0, 0, 0, 0.16);

// Separator
$separator-color: rgba(0, 0, 0, 0.10);
