@import "../../styles/colors";
@import "../../styles/flex";
@import "../../styles/dimentions";

.welcomeCard {

	&Container {
		@include flex(column, flex-start, center);

		width: 600px;
		box-sizing: border-box;
		margin-top: 60px;
	}

	&Header {
		font-family: 'Muli', sans-serif;
		padding: 20px;
	}

	&Duration {
		font-size: 14px;
		color: #818181;
		font-weight: bold;
	}
}
.filledButton {
	width: 200px !important;
}
